import React, { Fragment } from 'react';
import './success-item.scss';
import moment from 'moment';
import { icons, images } from '../../../../../assets';
import { DATE_FORMAT } from '../../../../../utils/constants';
import {MdAdd} from "react-icons/md"; 
import { splitString } from '../../../../../utils/splitString';
export const SuccessItem = ({succes,action,succesDetail}) => {

  const {id,name,validated,validationDate,ressources,illustration} = succes
  const validDate = validationDate ? moment(validationDate).format(DATE_FORMAT) : '';
  return <Fragment>
    {
      !validated && !succes['offset'] ?
        <div className={'success-item'} onClick={()=>succesDetail(id)}  >
          <div className={'header-content'} >
            <img src={illustration || images.success2} alt={ name || "logo"} /> 
          </div>
        <div className={'success-detail'} >
          <h3>{splitString(name,100) }</h3>
          <div className={'actions'} >
            <div className={'medias'} >
              {
                ressources?.documents.length > 0 && <i className={'material-icons'}  > {icons.FILE} </i>
              }
              {
                ressources?.videos.length > 0 && <i className={'material-icons'}  > {icons.PLAY} </i>
              }
              
            </div>
            <div className={'btn'} >
              {
                validated ?  <i className={'material-icons'} > {icons.CHECK_CIRCLE} </i> :   <i className={'material-icons fill-1'} > {icons.ARROW_FORWARD} </i>
              }         
            </div>
          </div>
        </div>
      </div> :
          validated && !succes['offset'] ?
          <div className={'success-item'} onClick={()=>succesDetail(id)}  >
          <div className={'success-validated'} >
            <h3>{splitString(name,100) }</h3>
            <p >{ validDate }</p>
          <div className={'actions'} >
            <div className={'medias'} >
            {
                ressources?.documents.length > 0 && <i className={'material-icons'}  > {icons.FILE} </i>
              }
              {
               ressources?.videos.length > 0 && <i className={'material-icons'}  > {icons.PLAY} </i>
              }
              
            </div>
            <div className={'btn'} >
               <i className={'material-icons'} > {icons.CHECK_CIRCLE} </i> 
            </div>
          </div>
        </div>
          </div> :
          <div className={'success-item'} onClick={action && action}>
            <div className={'header-content'} >
              <div className={'default-header'} >
                <div className={'circle'} >
                  <i className={'material-icons fill-1'} > {icons.ADD} </i>
                </div>
              </div>
            </div>
            <div className={'success-detail'} >
            <h3 className={'text'} >{"Afficher tous les succès" }</h3>
            <div className={'actions'} >
              <div className={'medias'} >
              </div>
              <div className={'btn'} >
                <i className={'material-icons fill-1'} > {icons.ARROW_FORWARD} </i>     
              </div>
            </div>
        </div>
          </div>
      }
    </Fragment>
    
  
};
