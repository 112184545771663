import React, { useState } from 'react';
import './link-item.scss';
import { icons } from '../../../../assets';
import { Button, ButtonColors } from '../../../components';
import { getIcon, iconList } from '../../../../utils';
import { IconPicker } from './iconPicker';
import { isNotEmpty, isURL, hasIcon, isMailTo } from './validation';

export const LinkItem = React.memo(({ strings, link, saveLink, editLink, deleteLink, isEdited, disableEdition}) => {
  const [isIconPickerVisible, setIsIconPickerVisible] = useState(false);
  const [linkItem, setLinkItem] = useState({id: link.id, label: link.label, url: link.url, icon: link.icon});
  const initialLink = link;

  const isValidUrlOrMail = linkItem.icon == iconList.MAIL ? isMailTo(linkItem.url) : isURL(linkItem.url);
  const isValidLabel = isNotEmpty(linkItem.label);
  const isValidIcon = hasIcon(linkItem.icon);
  const valueModified = JSON.stringify(initialLink) !== JSON.stringify(linkItem);

  const handleUpdateIcon = (icon) => {
    let newState = {};
    if (icon == iconList.MAIL) {
      newState.url = "mailto:";
    } else {
      newState.url = "https://";
    }
    newState.icon = icon;
    setLinkItem({ ...linkItem, ...newState });
  }
  const handleSetIsIconPickerVisible = e => setIsIconPickerVisible(!isIconPickerVisible);
  const handleLabelInputChange = e => setLinkItem({ ...linkItem, label: e.target.value });
  const handleUpdateUrl = e => setLinkItem({ ...linkItem, url: e.target.value });

  const handleSelectIcon = (value) => () => {
    handleUpdateIcon(value);
    setIsIconPickerVisible();
  };

  const handleUpdateLink = () => {
    if (!valueModified) {
      editLink(false);
    } else if (isEdited && isValidUrlOrMail && isValidLabel && isValidIcon && valueModified) {
      saveLink(linkItem)
    } else {
      editLink(true);
      setLinkItem({...linkItem, inEdition: true});
    }
  };

  return (
    <React.Fragment>
      {(isIconPickerVisible && isEdited) &&
        <IconPicker
          strings={strings}
          isIconPickerVisible={isIconPickerVisible}
          setIsIconPickerVisible={setIsIconPickerVisible}
          handleSelectIcon={handleSelectIcon}
        />
      }
      <li className={'link-item fade'}>
        <div className={'icon-label'}>
          <div
            className={`drop-down ${!isValidIcon && 'has-errors'} ${isEdited && 'is-editing'}`}
            onClick={isEdited ? handleSetIsIconPickerVisible : null}>
            {getIcon(linkItem.icon)}
            <i
              className={`material-icons ${!linkItem.icon && 'not-selected'}`}>
              {icons.ARROW_DROPDOWN}
            </i>
          </div>
          <input
            placeholder={strings('link_title')}
            className={`label ${!isValidLabel && 'has-errors'}`}
            value={linkItem.label}
            onChange={handleLabelInputChange}
            disabled={!isEdited}
          />
        </div>
        <input
          placeholder={strings('link_url')}
          className={`url ${!isValidUrlOrMail && 'has-errors'}`}
          value={linkItem.url}
          onChange={handleUpdateUrl}
          disabled={!isEdited}
        />
        <div className={'button-trash'}>
          <Button
            label={!linkItem.id || isEdited ? strings('save') : strings('edit')}
            icon={'edit'}
            className={'button'}
            color={!linkItem.id || isEdited ? ButtonColors.green500 : ButtonColors.green500}
            enabled={!disableEdition && isValidUrlOrMail && isValidLabel && isValidIcon}
            action={!linkItem.id || isEdited ? handleUpdateLink : () => editLink(true)}
          />
          <i
            className={'material-icons'}
            onClick={deleteLink}>
            {linkItem.id ? icons.DELETE : icons.CLOSE}
          </i>
        </div>
      </li>
    </React.Fragment>
  )
});
