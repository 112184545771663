import React from 'react';
import Iframe from 'react-iframe'
import { useLocation } from 'react-router';
import { notionUri } from '../../../utils/enum';
import './guide.scss';

export const Guide = ({notionUrl}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mini = searchParams.get('mini');
  const uri = notionUri[mini]
  return <Iframe
    url={`${notionUrl}${uri}`}
    width={"100%"}
    height={"100vh"}
    display="block"
    position="relative"
  />
}
