import React from 'react';
import { icons } from '../../../../assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Switch } from '../../../components';

export const Success = ({ success, handleCheck, handleDateChanged }) => {
  const { id, successId, label, validated, validationDate } = success;

  const validDate = validationDate ? Date.parse(validationDate) : null;

  return (
    <div className={'success row'}>
      <div className={'information row'}>
        <i className={`material-icons ${validated ? 'validated' : ''}`}>{validated ? icons.CHECK : icons.SCHEDULE}</i>
        <p className={'label'}>{label}</p>
      </div>
      <div className={'validation row'}>
        <DatePicker
          className={`${validated && 'disabled'}`}
          disabled={!validated}
          selected={validDate}
          onChange={handleDateChanged}
          maxDate={new Date()}
          dateFormat={'dd/MM/yyyy'}
        />
        <div className={'switch-item'}>
          <Switch checked={validated} handleCheckChange={handleCheck}/>
        </div>
      </div>
    </div>
  );
};
