import React, { Fragment, useState, useEffect } from 'react';
import './navigation.scss';
import { images, icons } from '../../../assets';
import { Input, ContactsList, Notifications, DropDown } from '../../components';
import { navigationMenu, Routes } from '../../../utils';
import { default as Item } from './item';
import { default as Menu } from './menu';
import { string } from 'prop-types';

export const Navigation = React.memo(
  ({
    strings,
    navigateTo,
    isStudent,
    isProjectDetail,
    isMiniDetail,
    projects,
    project,
    room,
    newNotifCount,
    logout,
    isSuccess,
     guides
  }) => {
    const [projectName, setProjectName] = useState(project.name);
    const [isContactDisplayed, setContactDisplayed] = useState(false);
    const [isNotifDisplayed, setNotifDisplayed] = useState(false);

    const projectsType = [...new Set(projects.list.map(project => project.type))]
    const selectedGuides = guides.filter(guide => {
      if (guide.type === 'M' && projectsType.includes('M13')) {
        return true
      }
      return projectsType.includes(guide.type)
    })


    let guide = {
      label: '',
      route: ``,
    }
    if (isProjectDetail) {
    const guideForProjectDetail = guides.filter(guide => {
      if (project.type === 'M13' && guide.type === 'M') {
        return true
      }

      return project.type === guide.type
    })
    guide = guideForProjectDetail ? guideForProjectDetail[0] : {}
    }
    if (!guide) {
      guide = {
        label: '',
        route: ``,
      }
    }


    const filterMenuitems = () => {
      if (isSuccess) {
        return navigationMenu.filter(item => item.label !== 'tool_box');
      } else {
        return navigationMenu;
      }
    }
    useEffect(() => {
      setProjectName(project.name);
    }, [project]);


    const onContactToggle = () => {
      const body = document.querySelector('body');
      body.style.overflow = isContactDisplayed ? 'scroll' : 'hidden';

      setContactDisplayed(!isContactDisplayed);
    };

    const onNotifToggle = () => {
      const body = document.querySelector('body');
      body.style.overflow = isNotifDisplayed ? 'scroll' : 'hidden';

      setNotifDisplayed(!isNotifDisplayed);
    };

    const handleRedirectTo = () => (value) => {
      console.log(value)
      const position = value.value
      const route = selectedGuides[position].route
      navigateTo(route)
    };

    return (
      <div id={'navigation'}>
        <div id={'fixed-wrapper'}>
          {isProjectDetail || isMiniDetail ? (
            <Fragment>
              <div className="logo-group">
                <img
                  onClick={() => navigateTo(Routes.HOME)}
                  className={'logo-epa'}
                  src={images.epaLogoTurquoise}
                  alt={'main-logo'}
                />
                {isProjectDetail ? (
                  <Input
                    type={'text'}
                    placeholder={strings('dashboard')}
                    className={'project-name'}
                    inputClassName={`project-name-input disabled`}
                    defaultValue={strings('dashboard')}
                    value={strings('dashboard')}
                  />
                ) : (
                  <div className={'mini-name'}  >{room && room.mini ? room.mini.name : ''}</div>
                )}
              </div>
              <div className={'icons'}>
                {
                  isProjectDetail && !isStudent ? (
                    <a href={guide.route ?? ''}>
                      <span className="tool-box">
                        {guide.label}
                      </span>
                    </a>
                  ) : null
                }
                {isProjectDetail ? (
                  <a target="_blank" href={navigationMenu[1].route}>
                    <i className="bx bxs-package tool_box_icon" ></i>
                    <span className="tool-box">{strings('tool_box')}</span>
                  </a>
                ) : null}
                {!isStudent && (
                  <i
                    className={` material-icons profile`}
                    onClick={() => navigateTo(Routes.PROFILE)}
                  >
                    {icons.PROFILE}
                  </i>
                )}
                <i
                  className={`${isContactDisplayed && 'isOrange'} icon-right material-icons`}
                  onClick={onContactToggle}
                >
                  {icons.PHONE}
                </i>
                <div className={'badge-wrapper'}>
                  <i className={'material-icons'} onClick={onNotifToggle}>
                    {icons.NOTIF}
                  </i>
                  {newNotifCount > 0 && <p>{newNotifCount > 9 ? '+9' : newNotifCount}</p>}
                </div>
                <i className={`material-icons logout`} onClick={logout}>
                  {icons.LOGOUT}
                </i>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Menu />
              <img
                onClick={() => navigateTo(Routes.HOME)}
                className={'logo-epa-tablette'}
                src={images.epaLogoTurquoise}
                alt={'main-logo'}
              />
              <ul className={'items'}>
                {
                filterMenuitems().map((item, index) => (item.dropdown ?
                    <DropDown
                      label=''
                      key={item.dropdown.label}
                      className={'dropdown-menu'}
                      current={`${strings(item.label)}`}
                      data={selectedGuides.map((item) => item.label)}
                      handleClickItem={handleRedirectTo('classes', index, selectedGuides)}
                    />
                    :
                  <Item
                    key={index}
                    text={strings(item.label)}
                    route={item.route}
                    newTab={item.newTab}
                  />
                ))

                }
              </ul>
              <div className={'icons'}>
              {isSuccess ? (
                  <a target="_blank" href={navigationMenu[1].route}>
                    <i className="bx bxs-package tool_box_icon" ></i>
                    <span className="tool-box">{strings('tool_box')}</span>
                  </a>
                ) : null}
                {!isStudent && (
                  <i
                    className={` material-icons profile`}
                    onClick={() => navigateTo(Routes.PROFILE)}
                  >
                    {icons.PROFILE}
                  </i>
                )}
                <i
                  className={`${isContactDisplayed && 'isOrange'} material-icons icon-right`}
                  onClick={onContactToggle}
                >
                  {icons.PHONE}
                </i>
                <div className={'badge-wrapper'}>
                  <i className={'material-icons'} onClick={onNotifToggle}>
                    {icons.NOTIF}
                  </i>
                  {newNotifCount > 0 && <p>{newNotifCount > 9 ? '+9' : newNotifCount}</p>}
                </div>
                <i className={` material-icons logout`} onClick={logout}>
                  {icons.LOGOUT}
                </i>
              </div>
            </Fragment>
          )}
          {isContactDisplayed && (
            <div className={`indicator ${isContactDisplayed && 'less-margin'}`} />
          )}
        </div>
        <ContactsList displayed={isContactDisplayed} />
        <Notifications key={new Date} displayed={isNotifDisplayed} handleClose={onNotifToggle} />
      </div>
    );
  },
);
